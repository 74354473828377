//
// progress
//

.progress {
  border-radius: $progress-border-radius;
  overflow: visible;
  // height: rem(20);

  .progress-bar {
    border-radius: $progress-border-radius;
    position: relative;
    overflow: visible;
    // height: rem(20);
  }
}
