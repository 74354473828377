//
// buttons
//

// colors
@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// text + icon btn
.btn-with-icon {
  position: relative;
  padding-right: $btn-padding-x * 2;
  text-align: left;

  .fa-solid,.fa-regular,.fa-light,.fa-thin,.fa-duotone,.bi {
    @include translate(0, -50%);
    position: absolute;
    top: 50%;
    right: $btn-padding-x;
  }

  &.btn-sm {
    padding-right: $btn-padding-x-sm * 2;

    .fa-solid,.fa-regular,.fa-light,.fa-thin,.fa-duotone,.bi {
      right: $btn-padding-x-sm;
    }
  }

  &.btn-lg {
    padding-right: $btn-padding-x-lg * 2;

    .fa-solid,.fa-regular,.fa-light,.fa-thin,.fa-duotone,.bi {
      right: $btn-padding-x-lg;
    }
  }
}

// icon btn
.btn-icon {
  position: relative;
  box-sizing: content-box;
  width: 1.5em;
  height: 1.5em;
  padding: $btn-padding-y;

  &[class*="-sm"] {
    padding: $btn-padding-y-sm;
  }

  &[class*="-lg"] {
    padding: $btn-padding-y-lg;
  }

  &[class*="-xl"] {
    padding: $btn-padding-y-lg * 1.5;
    font-size: rem(24);
  }

  .bi {
    @include translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.btn img {
  width: auto;
  height: rem(32);
}

.btn-white:hover {
  background-color: $gray-100;
}

.btn-outline-light {
  color: $black;
}

.btn-filter {
  @include nofocus;
  display: inline-block;
  border: $border-width solid $border-color;
  color: $secondary;

  &:not(:last-child) {
    margin-right: rem(4);
  }

  &:hover {
    border-color: $black;
  }

  &.current {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}
