// Select

.form-select {
  color: $form-select-color-alt;
  background-color: $form-select-bg-alt;
  background-image: escape-svg($form-select-indicator-alt);
  border: $form-select-border-width solid $form-select-border-color-alt;
  @include box-shadow($form-select-box-shadow-alt);

  &:focus {
    border-color: $form-select-focus-border-color-alt;
    @if $enable-shadows {
      @include box-shadow($form-select-box-shadow-alt, $form-select-focus-box-shadow-alt);
    } @else {
      box-shadow: $form-select-focus-box-shadow-alt;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    background-image: none;
  }

  &:disabled {
    color: $form-select-disabled-color-alt;
    background-color: $form-select-disabled-bg-alt;
    border-color: $form-select-disabled-border-color-alt;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $form-select-color-alt;
  }
}
