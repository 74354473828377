// fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

// bootstrap & variables
@import "~bootstrap/scss/functions";
@import "variables";
@import "utilities";
@import "user";
@import "fonts";
@import "~bootstrap/scss/bootstrap";

@import "components/_functions";
@import "variables-alt";

// components
@import "components/_index";
