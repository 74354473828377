//
// widgets
//

// countdown
.countdown {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;

  .countdown-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 50%;

    @include media-breakpoint-up(md) {
      width: 25%;
    }
  }

  .countdown-value {
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;

    span {
      font-size: rem(40);
      font-weight: $font-weight-bold;
      line-height: 1em;
    }
  }
}

.countdown-decorated {
  .countdown-item {
    padding: rem(4);

    &::before {
      display: block;
      content: "";
      width: 1px;
      padding-top: 100%;
    }
  }

  .countdown-value {
    position: absolute;
    top: rem(4);
    right: rem(4);
    bottom: rem(4);
    left: rem(4);
    align-items: center;
    justify-content: center;
    background: rgba($black, 0.25);

    span {
      font-size: rem(48);
    }
  }
}

// counter
.counter {
  @include nofocus;
  display: inline-flex;
  align-items: center;
  user-select: none;

  .counter-value {
    @include nofocus;
    transition: all 0.3s ease-in-out;
    appearance: none;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    line-height: 1.5em;
    text-align: center;
    font-size: inherit;
    color: inherit;
    appearance: textfield;
    border: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus,
    &:hover {
      color: $primary;
    }
  }

  .counter-minus,
  .counter-plus {
    transition: all 0.1s;
    width: 1em;
    height: 1.5em;
    text-align: center;
    line-height: 1.5em;
    cursor: pointer;

    &:hover {
      color: $black;
    }

    &:active {
      color: $primary;
    }
  }
}

.sticky-top {
  transition: top 0.2s;
}

.headroom--not-top {
  .sticky-top {
    top: 0;
  }

  .toc-container {
    max-height: 100vh;
  }

  &.headroom--pinned {
    .sticky-top {
      top: rem(90);
    }
  }
}

// toc
.toc-container {
  padding: rem(32) 0;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

// file structure
.file-structure {
  padding: 0;
  list-style: none;
  color: $black;

  li:not(:first-child) {
    margin-top: rem(4);
  }

  ul {
    padding-left: rem(32);
    list-style: none;

    li:first-child {
      padding-top: rem(4);
    }

    li:last-child {
      padding-bottom: rem(4);
    }
  }

  a,
  span {
    position: relative;
    color: inherit;
    display: block;
    padding-left: rem(32);
    line-height: rem(32);

    &::before {
      position: absolute;
      left: 0;
      content: "";
      font-family: bootstrap-icons !important;
      content: "\f38b";
      font-size: rem(24);
      color: inherit;
    }
  }

  a,
  span.folder {
    color: $black;

    &:hover {
      color: $primary;
    }

    &[aria-expanded="true"] {
      &::before {
        content: "\f3d2";
      }
    }

    &::before {
      content: "\f3d3";
    }
  }

  span.folder {
    &:hover {
      color: $black;
    }

    &::before {
      content: "\f3d7";
    }
  }
}
