//
// your custom scss goes here
//

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
   -webkit-tap-highlight-color: transparent;
   -webkit-touch-callout: none;
}

.disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

// Blog Content

#article-body h2 {
		font-size: 1.5rem;
}
#article-body h3 {
	font-size: 1.25rem;
}
#article-body a {
	@extend %underline;
}
#article-body blockquote {
	font-style: italic;
}


.step-list {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 55px;

  li {
    position: relative;
    min-height: 40px;
    counter-increment: item;

    &:not(:first-child) {
      margin-top: 20px;
    }

    &::before {
      // @include translate(0,-50%);
      position: absolute;
      top: .9em;
      left: -55px;
      content: counter(item);
      color: $white;
      // font-weight: 600;
      line-height: 36px;
      text-align: center;
      display: block;
      width: 40px;
      height: 40px;
      background: $primary;
      border-radius: 50%;
      border: 2px solid $primary;
    }

    li {

      &::before {
        color: $secondary;
        background: transparent;
        border-color: $primary;
      }

      &:not(:first-child) {
        margin-top: .5rem;
      }
    }
  }

  ul, ol {
    padding-left: 45px;
    margin-top: 20px;
    margin-bottom: 20px;
    list-style: none;
    counter-reset: item;
  }

  // p {
  //   &:not(:last-child) {
  //     margin-bottom: 10px;
  //   }
  // }
}