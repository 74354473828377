//
// icons
//

.icon-box {
  position: relative;
  display: inline-flex;
  width: rem(56);
  min-width: rem(56);
  height: rem(56);
  align-items: center;
  justify-content: center;
  font-size: rem(20);

  &[class*="-sm"] {
    min-width: rem(40);
    width: rem(40);
    height: rem(40);
    font-size: $font-size-lg;
  }

  &[class*="-lg"] {
    min-width: rem(72);
    width: rem(72);
    height: rem(72);
    font-size: rem(24);
  }
}

.bi {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
}
