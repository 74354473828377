//
// mixins & functions
//

// rem calculator
@function rem($size) {
  $rem: $size / 16;
  @return #{$rem}rem;
}

// vendor prefixes
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  // output standard non-prefixed declaration
  #{$property}: $value;
}

// helpers
@mixin translate($vertical, $horizontal) {
  transform: translate($vertical, $horizontal);
}

@mixin selection($type) {
  user-select: $type;
}

@mixin text-shadow($size: 16px) {
  text-shadow: 0px 0px $size rgba($black, 0.4);
}

@mixin translateX($value) {
  transform: translateX($value);
}

@mixin translateY($value) {
  transform: translateY($value);
}

@mixin transition($time: 0.2s, $type: all, $delay: 0s) {
  transition: $type $time;
  transition-delay: $delay;
}

@mixin nofocus {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

@mixin transitionfix {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

// placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}



@mixin form-validation-state-alt(
    $state,
    $color,
    $icon,
    $tooltip-color: color-contrast($color, $color-contrast-dark-alt, $color-contrast-light-alt, $min-contrast-ratio-alt),
    $tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity-alt),
    $focus-box-shadow: 0 0 $input-btn-focus-blur-alt $input-focus-width rgba($color, $input-btn-focus-color-opacity-alt)
  ) {
    .#{$state}-feedback {
      color: $color;
    }
  
    .#{$state}-tooltip {
      color: $tooltip-color;
      background-color: $tooltip-bg-color;
    }
  
    .form-control {
      @include form-validation-state-selector($state) {
        border-color: $color;
  
        @if $enable-validation-icons {
          background-image: escape-svg($icon);
        }
  
        &:focus {
          border-color: $color;
          box-shadow: $focus-box-shadow;
        }
      }
    }
  
    .form-select {
      @include form-validation-state-selector($state) {
        border-color: $color;
  
        @if $enable-validation-icons {
          &:not([multiple]):not([size]),
          &:not([multiple])[size="1"] {
            background-image: escape-svg($form-select-indicator-alt), escape-svg($icon);
          }
  
          &[multiple],
          &[size]:not([size="1"]) {
            background-image: escape-svg($icon);
          }
        }
  
        &:focus {
          border-color: $color;
          box-shadow: $focus-box-shadow;
        }
      }
    }
  
    .form-check-input {
      @include form-validation-state-selector($state) {
        border-color: $color;
  
        &:checked {
          background-color: $color;
        }
  
        &:focus {
          box-shadow: $focus-box-shadow;
        }
  
        ~ .form-check-label {
          color: $color;
        }
      }
    }
  }
  



