//
// users
//

.avatar {
  position: relative;
  display: inline-block;
  width: rem(56);
  height: rem(56);

  span {
    font-size: $font-size-base;
  }

  &[class*="-sm"] {
    width: rem(40);
    height: rem(40);

    span {
      font-size: $font-size-sm;
    }
  }

  &[class*="-lg"] {
    width: rem(72);
    height: rem(72);

    span {
      font-size: rem(20);
    }
  }

  img {
    max-width: 100%;
  }

  span {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: $white;
  }
}

.avatar-list {
  display: inline-flex;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    z-index: 2;

    &:hover {
      z-index: 4;
    }
  }

  li:not(:first-child) {
    margin-left: rem(-16);
  }
}
