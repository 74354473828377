//
// doc
//
//

.instruction-sidebar {
	// padding: $card-spacer-x;
	border-right: $border-width solid $border-color;
}

.instruction-content-header {
	// margin-left: -($card-spacer-x / 2);
	// margin-right: -($card-spacer-x / 2);
	// margin-bottom: $grid-gutter-width;
	// padding: $grid-gutter-width / 2;
	border-bottom: $border-width solid $border-color;
	@extend .py-3;

	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
		// padding: $card-spacer-x 3.125rem;
	}

	@include media-breakpoint-down(md) {
		border-top: $border-width solid $border-color;
		background: $white;
	}

	.breadcrumb {
		margin: 0;
		padding: 0;
		border: 0;
	}
}


.instruction-content-body {
	margin-top: 0!important;

	> div {
		// padding: $grid-gutter-width / 2;
		@extend .py-5;
	}

	> aside {
		padding: $grid-gutter-width / 2;
		border-left: $border-width solid $border-color;
	}

	@include media-breakpoint-up(lg) {
		> div {
			// padding: 3.125rem;
			@extend .py-5;
		}

		> aside {
			padding: 3.125rem $card-spacer-x;
		}
	}

	.toc {
		margin-left: -$card-spacer-x;
		margin-right: -$card-spacer-x;
	}
}